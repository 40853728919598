import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input', 'item' ]

  appendToInput(element) {
    var value = this.inputTarget.value

    if (value) {
      this.inputTarget.value = `${value}, ${element.currentTarget.textContent}`
    } else {
      this.inputTarget.value = `${element.currentTarget.textContent}`
    }
  }
}
