import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'owner', 'partner' ]

  switchGender(event) {
    var owner   = this.ownerTargets.indexOf(event.currentTarget)
    var partner = this.partnerTargets.indexOf(event.currentTarget)

    var isOwner   = (owner === 0 || owner === 1)
    var isPartner = (partner === 0 || partner === 1)

    if (isOwner) {
      this.switchPartnerGender(event)
    } else if (isPartner) {
      this.switchOwnerGender(event)
    }
  }

  switchPartnerGender(event) {
    var ownerGender = event.currentTarget.value

    this.partnerTargets.find( ({ value }) => value !== ownerGender ).checked = true
  }

  switchOwnerGender() {
    var partnerGender = event.currentTarget.value

    this.ownerTargets.find( ({ value }) => value !== partnerGender ).checked = true
  }
}
