import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['shareButton', 'copyButton']

  connect() {
    this.title = 'Sexplore what you and I want to do between the sheets and elsewhere… 😘'
    this.url   = this.data.get('partnerUrl')

    if (navigator.share) {
      if (this.hasShareButtonTarget) this.shareButtonTarget.classList.remove('hidden')
    } else {
      if (this.hasShareButtonTarget) this.copyButtonTarget.classList.remove('hidden')
    }
  }

  share() {
    if (!navigator.share) return

    navigator.share({
      title: this.title,
      url:   this.url
    })
  }
}
