import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'wrapper', 'container', 'content', 'template', 'background']

  view(e) {
    e.preventDefault()

    if (e.target !== this.wrapperTarget &&
      !this.wrapperTarget.contains(e.target)) return

    if (this.open) {
      this.lockScroll()

      this.getContent(this.url)

      this.toggleScrollerOverflow()

      this.wrapperTarget.insertAdjacentHTML('afterbegin', this.template())

      this.open = true
    }
  }

  close(e) {
    e.preventDefault()

    if (this.open) {
      this.unlockScroll()

      this.toggleScrollerOverflow()

      if (this.hasContainerTarget) { this.containerTarget.remove() }

      this.open = false
    }
  }

  closeBackground(e) {
    if (e.target === this.backgroundTarget) { this.close(e) }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) { this.close(e) }
  }

  closeModalOnSubmit(e) {
    if (this.closeOnSubmit === 'true') {
      Turbolinks.visit(this.currentPage)

      this.close(e)
    }
  }

  getContent(url) {
    fetch(url).
      then(response => {
        if (response.ok) {
          return response.text()
        } else {
          return this.errorPartial(response)
        }
      })
      .then(html => {
        this.contentTarget.innerHTML = html
      })
      .catch((error) => {
        console.log(error)
      })
  }

  lockScroll () {
    let scrollbarWidth               = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`
    document.body.style.overflow     = 'hidden'
  }

  unlockScroll() {
    document.body.style.paddingRight = null
    document.body.style.overflow     = null
  }

  toggleScrollerOverflow() {
    var target = document.querySelectorAll('[data-target="remote.scroller"]')[0]

    if (target) { target.classList.toggle('overflow-hidden') }
  }

  template() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.innerHTML
    } else {
      return this.defaultTemplate()
    }
  }

  defaultTemplate() {
    return `
      <div data-target='remote.container'>
        <div class='modal-wrapper' data-target='remote.background' data-action='click->remote#closeBackground'>
          <div class='flex flex-col items-center justify-center w-full max-w-2xl mt-10 px-2 sm:w-4/5 md:w-2/3 sm:-mt-20' data-target='remote.content'>
            <span class='loader-spinner m-2'></span>
          </div>

          <button data-action='click->remote#close' class='absolute top-0 right-0 w-6 h-6 m-1 text-gray-200 z-20 m-2'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M6 18L18 6M6 6L18 18' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
          </button>
        </div>
      </div>
    `
  }

  errorPartial(response) {
    return `
      <p class='p-2 text-gray-600'>
        Error: ${response.status}
      </p>
    `
  }

  get open() {
    return this.data.get('open')
  }

  get url() {
    return this.data.get('url')
  }

  get closeOnSubmit()  {
    return this.data.get('closeOnSubmit') || false
  }

  get currentPage() {
    return this.data.get('currentPage') || false
  }

  set open(bool) {
    this.data.set('open', bool)
  }
}
