import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'content', 'author' ]

  connect() {
    this.replace()
  }

  replace() {
    this.contentTarget.textContent = this.testimonial.content
    this.authorTarget.textContent  = this.testimonial.author
  }

  get testimonial() {
    return this.testimonials[Math.floor(Math.random() * this.testimonials.length)]
  }

  get testimonials() {
    return JSON.parse(this.data.get('items'))
  }
}
