import { Controller } from 'stimulus'

const images    = require.context('../images/home', true, /\.jpg$/)
const imagePath = (name) => images(name, true)

export default class extends Controller {
  connect() {
    this.randomiseImage()
  }

  randomiseImage() {
    this.element.src = imagePath(`./${this.locale}/preview-${this.randomNumber}.jpg`)['default']
  }

  get locale() {
    return this.data.get('locale')
  }

  get randomNumber() {
    return Math.floor(Math.random() * 7) + 1
  }
}
