import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'items', 'prevLink', 'nextLink' ]

  nextPage(event) {
    event.preventDefault()

    let page = this.nextLinkTarget.querySelector("a[rel='next']")

    if (page == null) { return }

    this.loadMore(page.href)
  }

  prevPage(event) {
    event.preventDefault()

    let page = this.nextLinkTarget.querySelector("a[rel='next']")

    if (page == null) { return }

    this.loadMore(page.href)
  }

  loadMore(url) {
    if (this.loading) { return }

    this.loading = true

    Rails.ajax({
      type:     'GET',
      url:      url,
      dataType: 'json',
      success:  (data) => {
        this.itemsTarget.innerHTML = data.items

        this.nextLinkTarget.innerHTML = data.next_link
        this.prevLinkTarget.innerHTML = data.prev_link

        this.loading = false
      }
    })
  }
}
