import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "button" ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value)
    .then(() => {
      this.buttonTarget.textContent = this.data.get('copiedLabel')
    })
    .catch(err => {
      this.buttonTarget.textContent = this.data.get('copiedErrorLabel')
    });
  }
}
