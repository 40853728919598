export function postResponse(event, csrfToken) {
  let direction = event.throwDirection.toString() === Symbol('LEFT').toString() ? 'left' : 'right'
  let response  = direction == 'right' ? 'yes' : 'no'
  let url       = `${event.target.dataset.swipeableUrl}&value=${response}`

  fetch(url, {
    method: 'POST',
    body: null,
    headers: {
      'x-csrf-token': csrfToken,
      'content-type': 'application/json',
      'accept':       'application/json'
    }
  })
}
