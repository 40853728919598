import { Controller } from 'stimulus'
import Swing from 'swing'
import { postResponse } from './swipeable/post_response.js'

export default class extends Controller {
  static targets = ['card', 'modalStart', 'modalEnd', 'progressBar']

  connect() {
    window.Swing = require('swing')
    this.stack   = window.Swing.Stack(this.config)
    this.cards   = this.cardTargets

    this.showProgress()

    this.cards.forEach((targetElement) => {
      this.stack.createCard(targetElement)
    })

    this.stack.on('throwout', (event) => {
      this.throwOut(event)
    })

    this.stack.on('throwin', (event) => {
      var labels = event.target.querySelectorAll('.swipeableLabel')

      for (var i = 0; i < labels.length; i++) {
        labels[i].style.opacity = 0
      }
    })

    this.showOnboardingModal()

    if (this.cardsCount == 0) {
      this.showExitModal()
    }
  }

  throwOut(event) {
    postResponse(event, this.csrfToken)

    let card = this.stack.getCard(event.target)

    card.on('throwoutend', () => {
      event.target.remove()
    })

    if (this.cardsCount == 1) {
      this.showExitModal()
    }

    this.data.set('count', this.cardTargets.length - 1)

    this.showProgress()
  }

  throwOutCard(event) {
    // FIXME: what a mess; can we use event.currentTarget?!
    let card             = this.stack.getCard(event.target.parentNode.parentNode.parentNode)

    let positiveResponse = event.target.dataset.value == 'yes'
    let negativeResponse = event.target.dataset.value == 'no'

    if (negativeResponse) {
      card.throwOut(-90, 0)
    } else if (positiveResponse) {
      card.throwOut(90, 0)
    }
  }

  showProgress() {
    this.progressBarTarget.classList.remove('slideUpInPlace')
    this.progressBarTarget.classList.add('slideUp')
    this.progressBarTarget.classList.remove('opacity-100')
    this.progressBarTarget.textContent = ''

    var count = this.cardsCount

    var firstResponse         = Math.ceil(this.totalQuestionsCount - 1)
    var quarterResponses      = Math.ceil(this.totalQuestionsCount / 4)
    var halfResponses         = Math.ceil(quarterResponses * 2)
    var threequarterResponses = Math.ceil(quarterResponses * 3)

    switch (true) {
      case (count == 1):
        this.progressBarTarget.textContent = this.data.get('lastResponseLeftText')

        this.showProgressBanner()

        break
      case (count == quarterResponses):
        this.progressBarTarget.textContent = this.data.get('quarterResponsesLeftText').replace(/%{count}/g, this.cardsCount)

        this.showProgressBanner()

        break
      case (count == halfResponses):
        this.progressBarTarget.textContent = this.data.get('halfResponsesLeftText').replace(/%{count}/g, this.cardsCount)

        this.showProgressBanner()

        break
      case (count == threequarterResponses):
        this.progressBarTarget.textContent = this.data.get('threequarterResponsesLeftText').replace(/%{count}/g, quarterResponses)

        this.showProgressBanner()

        break
      case (count == firstResponse):
        if (this.firstSurvey !== 'true') return

        this.showProgressBanner()

        this.progressBarTarget.textContent = this.data.get('firstResponseEver')

        break
    }
  }

  showProgressBanner() {
    this.progressBarTarget.classList.add('opacity-100')
    this.progressBarTarget.classList.remove('slideUp')
    this.progressBarTarget.classList.add('slideUpInPlace')
  }

  showOnboardingModal() {
    if (this.firstSurvey === 'true' && this.cards.length == this.totalQuestionsCount) {
      this.modalStartTarget.classList.remove('hidden')
    }
  }

  closeModalStart() {
    this.modalStartTarget.classList.add('hidden')
  }

  showExitModal() {
    this.modalEndTarget.classList.remove('hidden')
  }

  config = ({
    throwOutConfidence: (xOffset, yOffset, element) => {
      let direction = (parseInt(xOffset) / parseInt(element.offsetWidth)) * 1.9

      if (direction > 0) {
        element.querySelector('.swipeableLabelRight').style.opacity = direction
      } else if (direction < -0){
        element.querySelector('.swipeableLabelLeft').style.opacity =- direction
      }

      const xConfidence = Math.min(Math.abs(xOffset) / element.offsetWidth * 2.5, 1)
      const yConfidence = Math.min(Math.abs(yOffset) / element.offsetHeight * 2.5, 1)

      return Math.max(xConfidence, yConfidence)
    }
  })

  get csrfToken() {
    const metaCsrf = document.querySelector("meta[name='csrf-token']");

    return metaCsrf.getAttribute('content');
  }

  get cardsCount() {
    return this.data.get('count')
  }

  get firstSurvey() {
    return this.data.get('new')
  }

  get totalQuestionsCount() {
    return parseInt(this.data.get('totalQuestionsCount'))
  }

  set cardsCount(value) {
    this.data.set('count', value)
  }
}
